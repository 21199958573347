import React, { useState } from "react";
import "./ServicePageNew.scss";
import Logo from "../../Assets/HousinGramLogo.svg";
import LogoTitle from "../../Assets/HousinGram.svg";
import { RealEstateProjects } from "../../Assets/Data/constants";
import { BannerData } from "../../Assets/Data/constants";
import toast from "react-hot-toast";

function ServicePageNew() {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    company: "",
    jobTitle: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log(formData);
    const url =
      "https://script.google.com/macros/s/AKfycbyRREVOExF7YelQD_iwNOlS6Hmrj8e0oJZ_OCirHKI76Vu9z-VlRGjEoRIfNAqmcF56/exec";

    fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then(() => {
        toast.success("Form submitted successfully!");
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          company: "",
          jobTitle: "",
        });
      })
      .catch((error) => {
        toast.error("Failed to submit form: " + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="servicePage">
      <div className="serviceHero">
        <div className="serviceProjects">
          <div className="Header">
            <div className="logo">
              <img src={Logo} alt="Logo" />
              <img src={LogoTitle} alt="Logo Title" />
            </div>
          </div>
          <h1 className="headingTitle">
            Easy way to manage your <br /> <span>Real-estate Project</span>
          </h1>
          <p className="heroDesc">
            All-in-one platform for developers to <br /> optimize operations and
            showcase projects
          </p>
        </div>
        <div className="serviceDemoForm">
          <form onSubmit={handleSubmit} className="landingForm">
            <div className="InputFields">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
              />
            </div>
            <div className="InputFields">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
              />
            </div>
            <div className="InputFields">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </div>
            <div className="InputFields">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Enter your company name"
              />
            </div>
            <div className="InputFields">
              <label htmlFor="jobTitle">Job Title (Optional)</label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleInputChange}
                placeholder="Enter your job title"
              />
            </div>
            <button className="contactBtn" type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Request Demo"}
            </button>
          </form>
        </div>
      </div>
      <div className="BannerPageContainer">
        <div className="bannerInnerContainer">
          <h2 className="BannerTitle">Why Housingram is better</h2>
          <div className="serviceListContainer">
            <div className="projectContainer">
              {RealEstateProjects.map((project, index) => (
                <div className="projectItem" key={index}>
                  <img src={project.img} alt="" className="projectLogo" />
                  <div className="content">
                    <h4 className="title">{project.title}</h4>
                    <p className="description">{project.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicePageNew;
