import React from "react";
import "./BannerPage.scss";
import { BannerData } from "../../Assets/Data/constants";
const BannerPage = () => {
	return (
		<div className="BannerPageContainer">
			<h1 className="BannerTitle">{BannerData && BannerData.title}</h1>
			<div className="BannerLogosContainer">
				{BannerData &&
					BannerData.logos?.map((l, index) => (
						<div className="bannerLogoContainer" key={index}>
							<img className="bannerLogo" src={l.img} alt="" />
						</div>
					))}
			</div>
		</div>
	);
};

export default BannerPage;
