import React, { useState } from "react";
import "./Footer.scss";
import Logo from "../../Assets/HousinGramLogo.svg";
import LogoTitle from "../../Assets/HousinGram.svg";
import RightArrow from "../../Assets/right-arrow.svg";
import SupportModal from "../../Components/SupportModal/SupportModal";
import { Modal } from "antd";

const Footer = () => {
	const [closeModal, setCloseModal] = useState(false);
	return (
		<div className="FooterContainer">
			<div className="leftSection">
				<div className="logo">
					<img src={Logo} alt="" />
					<img src={LogoTitle} alt="" />
				</div>
				{/* <div className="InputSearchField">
					<label htmlFor="">Get started now try our product</label>
					<div className="Inputcontainer">
						<input type="text" placeholder="Enter your email here" />
						<button className="circle">
							<img src={RightArrow} alt="" />
						</button>
					</div>
				</div> */}
				<p>© 2024 22 Century Innovations Pvt Ltd. All rights reserved</p>
			</div>
			<div className="rightSection">
				{/* <a href="Support">Support</a> */}
				{/* <a href="Help centre">Help centre</a> */}
				{/* <a href="Pricing">Pricing</a> */}
				<a href="/about">About</a>
				<a href="/contact-us">Contact us</a>
				<p onClick={() => setCloseModal(true)}>Support</p>
				<a href="/terms">Terms and Condtions</a>
				<a href="/privacy">Privacy Policy</a>
			</div>
			<Modal
				open={closeModal}
				footer={false}
				centered
				width="auto" // Set width to auto
				style={{ maxWidth: "none" }} // Disable any max-width
				onCancel={() => setCloseModal(false)}
			>
				<SupportModal setCloseModal={setCloseModal} />
			</Modal>
		</div>
	);
};

export default Footer;
