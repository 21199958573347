import VAISHALI from "../VAISHALI LOGO.svg";
import Logo2 from "../logo2.svg";
import Swastik from "../Swastik.svg";
import VBD from "../VBD.svg";
import Girja from "../image6.svg";
import Seghal from "../image5.svg";
import SeghalColored from "../SehgalColored.png";
import Surendra from "../image4.svg";
import Customer from "../customer.svg";
import Business from "../business.svg";
import ValueChain from "../valueChain.svg";
import CIRating from "../22ciRating.svg";
import BaazRating from "../baazRating.svg";
import Insight1 from "../insight1.svg";
import ContactImg from "../contactImg.svg";
import FullRating from "../fullRating.svg";

import AllInOnePlatformIcon from "../AllInOnePlatformIcon.png";
import HighlySecuredIcon from "../HighlySecuredIcon.png";
import TotalInsightsIcon from "../TotalInsightsIcon.png";
import CoversAllIcon from "../CoversAllIcon.png";
import BuilderOrientedIcon from "../BuilderOrientedIcon.png";
import PartnersInsightsIcon from "../PartnersInsightsIcon.png";
import DocumentsSafeIcon from "../DocumentsSafeIcon.png";
import AgreementsLegalIcon from "../AgreementsLegalIcon.png";
import ReportsIcon from "../ReportsIcon.png";

export const HeroSectionData = {
  title: "Transforming Real Estate Management",
  desc: "All-in-one platform for developers to optimize operations and showcase projects",
};

export const BannerData = {
  title: "Saved 30% for builders across India",
  logos: [
    { img: VAISHALI },
    { img: Logo2 },
    { img: Swastik },
    { img: VBD },
    { img: Girja },
    { img: Seghal },
    { img: Surendra },
  ],
};

export const RealstateData = {
  title: "Comprehensive Real Estate Solutions for...",
  card: [
    {
      img: Customer,
      title: "Customer",
      desc: "HousinGram enhances customer experience by streamlining workflows and boosting communication between developers and buyers. It ensures transparency in project progress, giving customers clear visibility into their investments. With real-time updates and insights, customers can track developments, leading to quicker, more reliable project completions and increased satisfaction.",
    },
    {
      img: Business,
      title: "Business",
      desc: "Our real estate productivity app boosts business efficiency by optimizing project delivery and enhancing team collaboration. It provides tools for real-time tracking, resource allocation, and task management, helping developers complete projects on time and within budget.",
    },
    {
      img: ValueChain,
      title: "Value Chain",
      desc: "HousinGram connects architects, vendors, contractors, and service providers within the real estate value chain by offering performance insights, timely payments, and project visibility. It helps them build credibility, expand their network, and access better opportunities through streamlined workflows, while developers benefit from improved efficiency and high-quality services from trusted partners.",
    },
  ],
};

export const InsightsPageData = [
  {
    title: "Empowering developers with actionable insights",
    desc: "HousinGram was founded by Karan Sehgal, leveraging over 15 years of experience in the real estate industry to create an all-in-one solution for developers and builders. Recognizing the complexities of real estate management, HousinGram aims to streamline operations, sales, and vendor relationships, enhancing efficiency across the board. Our vision is to empower real estate professionals to make informed decisions, optimize their projects, and build stronger communities, thus revolutionizing the real estate landscape through innovative, data-driven tools and insights.",
    rating: [
      { img: CIRating },
      {
        img: BaazRating,
      },
    ],
    img: Insight1,
  },
];

export const TestimonialsData = {
  subtitle: "Testimonials",
  title: "What clients says",
  desc: "Our goal is at the heart of all that we do. We make our clients happiness our sole priority.",
  cardsData: [
    {
      img: SeghalColored,
      name: "Sehgal Infra",
      city: "Jalandhar",
      rating: FullRating,
      says: "HousinGram transformed how we manage operations and sales. The app’s analytical tools helped us identify inefficiencies and improve performance across all business verticals. The team is responsive and supportive, ensuring seamless integration with our value chain. Our communication with vendors and customers has also improved, leading to faster decision-making and higher satisfaction.",
    },
    {
      img: VBD,
      name: "Ved Builders and Developers",
      city: "Bhopal",
      rating: FullRating,
      says: "Using HousinGram has been a game-changer for our business. Its comparative data analysis provided insights we never had before, boosting our sales and vendor management. The platform has simplified communication with our customers and team, making operations run smoother. We highly recommend it!",
    },
    {
      img: Girja,
      name: "Girja Colonisers",
      city: "Bhopal",
      rating: FullRating,
      says: "HousinGram’s all-in-one platform has streamlined our processes, from marketing to legal. The ease of coordinating with contractors and vendors has improved our project timelines. The support team is always available, and the app's user-friendly interface keeps us connected with our customers effortlessly.",
    },
    {
      img: Surendra,
      name: "Surendra Group",
      city: "Ahemdabad",
      rating: FullRating,
      says: "Managing multiple projects became much easier with HousinGram. Its powerful analytics gave us a clearer understanding of our business verticals, from HR to accounts. The app’s features strengthened our communication with suppliers and contractors, while the support team provided quick solutions whenever needed.",
    },
    {
      img: Swastik,
      name: "Swastik Developers",
      city: "Katni",
      rating: FullRating,
      says: "HousinGram helped us optimize our real estate operations. The app’s data-driven insights allowed us to make informed decisions, improving both sales and vendor relationships. Our interactions with clients and the team have never been smoother. The customer support from HousinGram is outstanding!",
    },
  ],
};

export const GetStartedData = {
  title: "Get Started",
  img: ContactImg,
};
export const RealEstateProjects = [
  {
    img: AllInOnePlatformIcon,
    title: "All in one platform",
    desc: "Comprises of Management, Operations, sales, marketing, Finances, Legal.",
  },
  {
    img: HighlySecuredIcon,
    title: "Highly Secured",
    desc: "Safeguards sensitive data across all operations.",
  },
  {
    img: TotalInsightsIcon,
    title: "Total insights",
    desc: "Provides comprehensive analytics and real-time data.",
  },
  {
    img: CoversAllIcon,
    title: "Covers All",
    desc: "Vendors, Architects, Contractors, Customers, different service providers.",
  },
  {
    img: BuilderOrientedIcon,
    title: "Builder Oriented",
    desc: "Tailored for developers and builders to streamline project management.",
  },
  {
    img: PartnersInsightsIcon,
    title: "Partners clear insights",
    desc: "Provides partners with clear, actionable insights into business.",
  },
  {
    img: DocumentsSafeIcon,
    title: "Documents kept safe",
    desc: "Stores critical documents securely with controlled access.",
  },
  {
    img: AgreementsLegalIcon,
    title: "Agreements & Legal noti",
    desc: "Manages and stores all legal agreements, contracts, and notices.",
  },
  {
    img: ReportsIcon,
    title: "Reports",
    desc: "Delivers detailed performance reports for each business vertical.",
  },
];

export const PrivacyPageData = {
  mainTitle: "Privacy Policy for HousinGram!",
  effectiveDate: "1/10/2024",
  desc: [
    "At HousinGram, a product of 22nd Century Innovation Pvt Ltd, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data when you use our website, iOS, or Android mobile applications.",
    "By using HousinGram’s services, you agree to the collection and use of information in accordance with this policy.",
  ],
  points: [
    {
      title: "Information We Collect",
      desc: "We may collect the following types of information from our users:",
      list: [
        {
          title: "Personal Information",
          desc: "When you register or interact with HousinGram, we may collect personal details like your name, email address, phone number, business details, location, and other contact information.",
        },
        {
          title: "Usage Data",
          desc: "We collect data on how you use the app or website, including IP addresses, device information, browser types, operating systems, access times, and pages viewed.",
        },
        {
          title: "Cookies",
          desc: "We use cookies and similar tracking technologies to enhance your user experience. You can choose to accept or decline cookies through your browser settings.",
        },
        {
          title: "Location Data",
          desc: "With your permission, we may collect precise location data to improve our services. You can disable location services via your device settings.",
        },
        {
          title: "Transaction Data",
          desc: "Information related to any purchases or transactions made within the app or platform may also be collected.",
        },
      ],
    },
    {
      title: "How We Use Your Information",
      desc: "We use your information for the following purposes:",
      list: [
        {
          title: "",
          desc: "To provide, maintain, and improve our services.",
        },
        {
          title: "",
          desc: "To facilitate communications between users and developers.",
        },
        {
          title: "",
          desc: "To personalize your experience on HousinGram, offering customized content and recommendations.",
        },
        {
          title: "",
          desc: "To provide customer support and respond to inquiries.",
        },
        {
          title: "",
          desc: "To analyze app usage and trends, monitor performance, and improve functionality.",
        },
        {
          title: "",
          desc: "To comply with legal obligations.",
        },
      ],
    },
    {
      title: "Sharing Your Information",
      desc: "We may share your data with:",
      list: [
        {
          title: "Service Providers",
          desc: "Third-party service providers who assist us with app functionality, analytics, and other services, under confidentiality agreements.",
        },
        {
          title: "Legal Compliance",
          desc: "We may share information if required by law or to protect the rights, property, or safety of our users or the public.",
        },
        {
          title: "Business Transfers",
          desc: "In the event of a merger, acquisition, or sale, your data may be transferred as part of the business transaction.",
        },
      ],
      bottomDesc:
        "We do not sell, rent, or trade your personal information to third parties.",
    },
    {
      title: "Data Security",
      desc: "We take data security seriously and implement measures such as encryption and secure servers to protect your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.",
      list: [],
    },
    {
      title: "Data Retention",
      desc: "We retain your personal data only as long as necessary for the purposes outlined in this policy or to comply with legal obligations. You may request deletion of your data at any time by contacting us at support@housingram.com.",
      list: [],
    },
    {
      title: "Your Rights",
      desc: "As a user, you have the following rights:",
      list: [
        {
          title: "Access and Correction",
          desc: "You can access and update your personal information via your account settings or by contacting us.",
        },
        {
          title: "Data Deletion",
          desc: "You can request that we delete your personal data, subject to legal or operational requirements.",
        },
        {
          title: "Opt-out of Communications",
          desc: "You can unsubscribe from promotional emails or opt out of push notifications through your device settings or by contacting us.",
        },
      ],
    },
    {
      title: "Children's Privacy",
      desc: "HousinGram does not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.",
    },

    {
      title: "Third-Party Links",
      desc: "Our app or website may contain links to third-party websites or services. We are not responsible for the privacy practices of these sites. We encourage users to review the privacy policies of any third-party services they engage with.",
    },
    {
      title: "Changes to This Privacy Policy",
      desc: "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated on 01/10/2024 Your continued use of the service after changes are made indicates your acceptance of the updated policy.",
    },
    {
      title: "Contact Us",
      desc: "If you have any questions or concerns about this Privacy Policy, please contact us at:",
      contact: {
        company: "22nd Century Innovation Pvt Ltd",
        email: "support@housingram.com",
        phone: "7619250836",
        address:
          "9, Idgah Hills, Near Anand Satsang Bhawan, Bhopal- 462001, Madhya Pradesh, India",
      },
    },
  ],
  footer: {
    divide: "--------------------",
    desc: "By using the HousinGram app or website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.",
  },
};

export const AboutPageData = {
  title: "About Us",
  desc: [
    "Welcome to 22nd Century Innovation Pvt Ltd, where we are shaping the future of real estate through cutting-edge technology and visionary solutions. Founded with a passion for innovation and deep expertise in real estate, we are committed to transforming the industry by providing developers, builders, and professionals with tools that simplify and elevate every aspect of their business.",
    "At the core of our mission is HousinGram, our flagship product designed to be the ultimate all-in-one platform for real estate developers. HousinGram seamlessly integrates the key business verticals Management, Operations, Sales, Marketing, Legal, Accounts and Finance, HR, and R&D into a single, easy-to-use app. It also connects with the external value chain, including architects, contractors, vendors, and end users, making it a comprehensive solution for developers seeking to streamline operations and enhance performance.",
    "What sets HousinGram apart is its powerful data-driven insights. Developers can track their performance across various business verticals, identifying inefficiencies and comparing their results with other builders anonymously. This comparative analysis helps developers not only understand their strengths but also pinpoint areas where they can improve, fostering a more efficient and competitive market.",
    "We believe that real estate management should be smarter, faster, and more efficient. That’s why 22nd Century Innovation Pvt Ltd is dedicated to creating solutions that empower developers to focus on what they do best building homes and communities. With our extensive experience in the real estate industry, we understand the challenges developers face and are passionate about solving them with innovative technology.",
    "Join us as we lead the real estate industry into the future. Let HousinGram be the tool that helps you achieve your vision, one project at a time.",
  ],
};

export const TermsAndConditionsData = {
  Title: "Terms and Conditions",
  EffectiveDate: "1/10/2024",
  desc: "Welcome to HousinGram, a product of 22nd Century Innovation Pvt Ltd. By accessing or using our website, mobile applications (iOS and Android), or any other services provided by HousinGram, you agree to comply with these Terms and Conditions (Terms). Please read them carefully, as they constitute a legal agreement between you and 22nd Century Innovation Pvt Ltd.",
  points: [
    {
      title: "Acceptance of Terms",
      desc: [
        "By registering for, accessing, or using the HousinGram website or app, you agree to abide by these Terms and any additional terms and conditions we may provide in the future. If you do not agree with these Terms, you must not use our services.",
      ],
    },
    {
      title: "Services Provided",
      desc: [
        "HousinGram provides a comprehensive platform for real estate developers to manage various aspects of their business, including operations, sales, marketing, legal, accounting, and more. Our platform allows users to access features such as comparative data analysis, vendor management, project showcases, and more.",
        "We reserve the right to modify, suspend, or discontinue any part of our service at any time without notice.",
      ],
    },
    {
      title: "Eligibility",
      desc: ["To use HousinGram, you must:"],
      list: [
        {
          title: "",
          desc: "Be at least 18 years old.",
        },
        {
          title: "",
          desc: "Have the authority to enter into these Terms on behalf of yourself or the organization you represent.",
        },
        {
          title: "",
          desc: "Provide accurate and complete information during registration and keep it updated.",
        },
      ],
    },
    {
      title: "Account Registration and Security",
      desc: [
        "To access certain features of HousinGram, you must create an account. You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for any activity that occurs under your account. You agree to notify us immediately of any unauthorized access or use of your account.",
        "HousinGram reserves the right to terminate your account or restrict access if you violate any of these Terms.",
      ],
    },
    {
      title: "Use of the Services",
      desc: [
        "You agree to use HousinGram in accordance with the following conditions:",
      ],
      list: [
        {
          title: "Lawful Use",
          desc: "You will not use HousinGram for any illegal or unauthorized purpose. You will comply with all applicable laws and regulations, including intellectual property and data protection laws.",
        },
        {
          title: "Accurate Information",
          desc: "You agree to provide accurate, current, and complete information and not to misrepresent your identity.",
        },
        {
          title: "Restricted Activities",
          desc: [
            {
              subTitle: "You may not:",
              SubList: [
                " Engage in any fraudulent activities.",
                "Upload viruses or malicious code.",
                "Attempt to bypass security features or interfere with the proper functioning of the platform.",
                "Scrape, copy, or repurpose any content from the platform without prior authorization.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "User Content",
      desc: [
        "By submitting, posting, or displaying any content through HousinGram (including, but not limited to, text, images, project data, and other materials), you grant us a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, display, and distribute such content on the platform.",
        "You are solely responsible for the content you post and must ensure it does not infringe upon the intellectual property rights or privacy of third parties. HousinGram reserves the right to remove any content that violates these Terms.",
      ],
    },
    {
      title: "Payments and Fees",
      desc: [
        "Some features of HousinGram may require payment. If you choose to make a purchase, you agree to pay all applicable fees and charges. We may update pricing and payment terms from time to time. All fees paid are non-refundable unless stated otherwise.",
        "If using third-party payment processors, you agree to their terms and conditions for payment processing.",
      ],
    },
    {
      title: "Intellectual Property Rights",
      desc: [
        "All intellectual property, including but not limited to the platform's design, software, logos, graphics, content, and features, belongs to 22nd Century Innovation Pvt Ltd or its licensors. You may not use, copy, or distribute any content without our express written consent.",
        "You are granted a limited, non-transferable license to use HousinGram solely for the purposes outlined in these Terms.",
      ],
    },
    {
      title: "Third-Party Services",
      desc: [
        "HousinGram may include links to third-party websites or services. We are not responsible for the content, functionality, or policies of third-party sites. You access these sites at your own risk and should review their respective terms and policies.",
      ],
    },
    {
      title: "Privacy Policy",
      desc: [
        "Your use of HousinGram is also governed by our Privacy Policy, which explains how we collect, use, and share your personal information. By using our services, you agree to the collection and use of information as outlined in the Privacy Policy.",
      ],
    },
    {
      title: "Termination",
      desc: [
        "We reserve the right to terminate or suspend your access to HousinGram without prior notice or liability if we believe you have violated these Terms or for any other reason at our sole discretion.",
        "Upon termination, you must cease all use of the platform, and any licenses granted to you will be revoked.",
      ],
    },
    {
      title: "Disclaimer of Warranties",
      desc: [
        "HousinGram is provided on an 'as is' and 'as available' basis without warranties of any kind, either express or implied. We do not warrant that:",
      ],
      list: [
        {
          title: "",
          desc: "The service will be uninterrupted, secure, or error-free.",
        },
        { title: "", desc: "Any errors or defects will be corrected." },

        {
          title: "",
          desc: "The platform will meet your expectations or requirements.",
        },
      ],
      descBottom:
        "We make no representations or warranties regarding the accuracy or completeness of any data or content provided through HousinGram.",
    },
    {
      title: "Limitation of Liability",
      desc: [
        "To the fullest extent permitted by law, 22nd Century Innovation Pvt Ltd shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from:",
      ],
      list: [
        { title: "", desc: "Your access to or use of HousinGram." },
        { title: "", desc: "Errors, mistakes, or inaccuracies of content." },
        {
          title: "",
          desc: "Any unauthorized access to or use of our servers and any personal or financial information stored therein.",
        },
      ],
      descBottom:
        "In no event shall our liability exceed the total amount paid by you for accessing the service (if applicable).",
    },
    {
      title: "Indemnification",
      desc: [
        "You agree to indemnify and hold harmless 22nd Century Innovation Pvt Ltd, its affiliates, directors, officers, employees, and agents from and against any claims, damages, losses, liabilities, and expenses arising from:",
      ],
      list: [
        { title: "", desc: "our use of the platform." },
        { title: "", desc: "Your violation of these Terms." },
        {
          title: "",
          desc: "Your violation of any third-party rights, including intellectual property rights.",
        },
      ],
    },
    {
      title: "Governing Law",
      desc: [
        "These Terms are governed by and construed in accordance with the laws of India. Any disputes arising out of or related to these Terms shall be subject to the exclusive jurisdiction of the courts located in Madhya Pradesh.",
      ],
    },
    {
      title: "Changes to the Terms",
      desc: [
        "We reserve the right to upIf you have any questions or concerns regarding these Terms, please contact us at:date or modify these Terms at any time. Any changes will be posted on this page, and your continued use of HousinGram after such changes constitutes acceptance of the updated Terms.",
      ],
    },
    {
      title: "Contact Information",
      desc: [
        "If you have any questions or concerns regarding these Terms, please contact us at:",
      ],
      contact: {
        company: "22nd Century Innovation Pvt Ltd",
        email: "support@housingram.com",
        phone: "7619250836",
        address:
          "9, Idgah Hills, Near Anand Satsang Bhawan, Bhopal- 462001, Madhya Pradesh, India",
      },
    },
  ],
  footer: {
    divide: "--------------------",
    desc: "By using HousinGram, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.",
  },
};

export const ContactPageData = {
  email: "support@housingram.com",
  phone: "9200450836, 7619250836",
  officialAddress:
    "9, Idgah Hills, Near Anand Satsang Bhawan, Bhopal- 462001, Madhya Pradesh, India",
  secondaryAddress:
    "22nd Century Innovations Private Limited, 4th Floor, 214 Marg Aditya Complex, Gaurav Nagar, Kothnoor Dinne, JP Nagar 7th Phase, Bengaluru 560078",
};
