import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Layout from "./Layout/Layout";
import ContactPage from "./pages/ContactPage/ContactPage";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import ServicePageNew from "./pages/ServicePage/ServicePageNew";

function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route
            path="/karanb10"
            element={<Navigate to="/karanb10/index.html" replace />}
          />
          <Route
            path="/sales-development-manager-p4nslz7sy"
            element={
              <Navigate
                to="/sales-development-manager-p4nslz7sy/index.html"
                replace
              />
            }
          />
          <Route path="/contact-us" element={
			<ServicePageNew />
			} />
          <Route path="/service" element={
			<Layout>
			<ServicesPage />
			</Layout>
			} 
			/>
          <Route path="/privacy" element={
			<Layout>
			<PrivacyPage />
			</Layout>
			} />
          <Route
            path="/about"
            element={
              <Layout>
                <AboutPage />
              </Layout>
            }
          />
          <Route
            path="/terms"
            element={
              <Layout>
                <TermsAndConditions />
              </Layout>
            }
          />
          {/* <Route
            path="/contact"
            element={
              <Layout>
                <ContactPage />
              </Layout>
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Router;
